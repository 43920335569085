import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Button, ButtonLink, Input } from '@rabbit/elements/shared-components';
import { useRef, useState } from 'react';
import { Address } from '@rabbit/data/types';
import { CarRegistrationShape } from '../../ModalNewRegistration/ModalNewRegistration';
import { TenantType } from '@rabbit/sage/views/RegistrationsView';
import { useTranslation } from 'react-i18next';
import { EditAddAddressModal } from '@rabbit/sage/components/molecules/account-management/EditAddAddressModal/EditAddAddressModal';

const addressSchema = Yup.object().shape({
  line1: Yup.string().trim().required('Please enter an address.'),
  line2: Yup.string().trim(),
  town: Yup.string().trim().required('Please enter a city / suburb.'),
  state: Yup.string().trim().required('Please enter a state / territory.'),
  postcode: Yup.string().trim().required('Please enter a post code.'),
  country: Yup.string().trim().required('Please enter a country.'),
  isDefault: Yup.bool().default(false),
});

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().trim().required('Please enter a first name'),
  last_name: Yup.string().trim().required('Please enter a last name'),
  consumer_email: Yup.string()
    .email('Please enter a valid email address')
    .required("Email can't be empty"),
  phone_number: Yup.string().trim().required('Please enter a phone number'),
  consumer_address: addressSchema.required(
    'Please enter at least one address.'
  ),
});

export interface CreateNewConsumerDetailsProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: CarRegistrationShape & ConsumerDetailsShape;
  tenantType: TenantType;
}

export interface ConsumerDetailsShape {
  first_name: string;
  last_name: string;
  consumer_email: string;
  phone_number: string;
  phone_number_e164?: string;
  consumer_address: Address;
}

export function CreateNewConsumerDetails(props: CreateNewConsumerDetailsProps) {
  const { t } = useTranslation();
  const { onChange, handleClose, data, tenantType } = props;
  const [addressModal, setAddressModal] = useState(false);
  const formikRef = useRef<FormikProps<ConsumerDetailsShape>>(null);
  const [consumerAddress, setConsumerAddress] = useState<Address | null>(
    data.consumer_address
  );

  const onSubmit = (values: any) => {
    const result = values;
    result.phone_number = values.phone_number_e164 ?? values.phone_number;
    onChange(tenantType === 'carTenants' ? 5 : 3, result);
  };

  const initialValues: ConsumerDetailsShape = {
    first_name: data.first_name || '',
    last_name: data.last_name || '',
    consumer_email: data.consumer_email || '',
    phone_number: data.phone_number || '',
    phone_number_e164: data.phone_number || '',
    consumer_address: data.consumer_address || [],
  };

  const onAddressAdded = async (address: Address) => {
    formikRef.current?.setFieldValue('consumer_address', address);
    setConsumerAddress(address);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={false}
        innerRef={formikRef}
      >
        {(props) => (
          <Form className="flex-col gap-3 p-5">
            <div className="flex gap-4">
              <Input
                type="text"
                name="first_name"
                label="First name*"
                settings={{
                  id: 'first_name',
                  placeholder: 'Enter consumer first name',
                  forceUpperCaseFirstLetter: true,
                }}
              />
              <Input
                type="text"
                name="last_name"
                label="Last name*"
                settings={{
                  id: 'last_name',
                  placeholder: 'Enter consumer last name',
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Input
                type="email"
                name="consumer_email"
                label="Consumer email*"
                settings={{
                  id: 'consumer_email',
                  placeholder: 'Enter consumer email',
                }}
              />
              <Input
                type="phone"
                name="phone_number"
                label="Phone number*"
                settings={{
                  id: 'phone_number',
                  placeholder: 'Enter consumer phone number',
                }}
              />
            </div>
            <div className="mt-4">
              <ButtonLink
                label={t('Residential address') + '*'}
                className={
                  props.errors.consumer_address
                    ? 'border-red-500 bg-red-100'
                    : ''
                }
                onClick={() => setAddressModal(true)}
              >
                {consumerAddress ? (
                  <div>
                    {consumerAddress?.postcode} {consumerAddress?.line1},{' '}
                    {consumerAddress?.line2} {consumerAddress?.town}{' '}
                    {consumerAddress?.state} {consumerAddress?.country}
                  </div>
                ) : (
                  <div>{t('Residential address')}</div>
                )}
              </ButtonLink>
            </div>
            <div className="mt-5 flex w-full gap-4">
              <Button
                kind="primary"
                type="submit"
                disabled={Object.keys(props.errors).length > 0}
              >
                Continue
              </Button>
              <Button kind="red" type="button" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {addressModal && (
        <EditAddAddressModal
          handleClose={() => setAddressModal(false)}
          mode={consumerAddress ? 'edit' : 'new'}
          kind={'customer'}
          address={
            consumerAddress || {
              line1: '',
              line2: '',
              town: '',
              state: '',
              postcode: '',
              country: '',
              isDefault: true,
            }
          }
          handleSubmit={onAddressAdded}
          handleDelete={async () => {
            formikRef.current?.setFieldValue('consumer_address', null);
            setConsumerAddress(null);
          }}
        />
      )}{' '}
    </div>
  );
}

export default CreateNewConsumerDetails;
