import { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import {
  Button,
  ButtonIcon,
  LoadingSpinner,
  Modal,
  formatUnixTime,
  WarrantyViewer,
} from '@rabbit/elements/shared-components';
import {
  SageConsumerDataShape,
  useGetConsumerData,
} from '@rabbit/bizproc/react';
import {
  DTService,
  DTService_Offer_With_Template,
  FBD_Retailer_Public,
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
  Warranty,
  WarrantyStatus,
} from '@rabbit/data/types';
import RegistrationsOtherProductsSection from '../components/templates/registrations/other-products-table/RegistrationsOtherProductsSection';
import RegistrationsCustDetailsSection from '../components/templates/registrations/customer-details/RegistrationsCustDetailsSection';
import RegistrationsHoldingDetailsSection from '../components/templates/registrations/holding-details/RegistrationsHoldingDetailsSection';
import RegistrationsHoldingSummarySection from '../components/templates/registrations/holding-summary/RegistrationsHoldingSummarySection';
import { getFavouriteStore, getUserPermissions } from '../utils/helpers';
import { useTranslation } from 'react-i18next';
import { TenantType } from './RegistrationsView';
import CarRegistrationsHoldingSummarySection from '../components/templates/registrations/holding-summary/CarRegistrationsHoldingSummarySection';
import { UserContext } from '../context/UserContext';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { Permissions } from '@rabbit/data/types';
import {
  Cog8ToothIcon,
  DocumentMinusIcon,
  PencilSquareIcon,
  PrinterIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useSageAPI } from '@rabbit/bizproc/react';
import {
  BL_Service,
  BL_Warranty,
  nestApiGetAllServiceOffers,
} from '@rabbit/bizproc/core';
import ModalEditWarranty from '../components/organisms/ModalEditWarranty/ModalEditWarranty';
import RegistrationsWarrantyDetailsSection from '../components/templates/registrations/RegistrationsWarrantyDetailsSection/RegistrationsWarrantyDetailsSection';
import { AppContext } from '@rabbit/app-context';
import { ModalHistoryLog } from '../components/organisms/ModalHistoryLog/ModalHistoryLog';
import ModalNewClaim from '../components/organisms/ModalNewClaim/ModalNewClaim';
import ModalNewClaimVehicle from '../components/organisms/ModalNewClaimVehicle/ModalNewClaimVehicle';
import InstallationDetails from '@rabbit/sage/components/templates/registrations/installation-details/installationDetails.tsx';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import WarrantyDetailsSection from '@rabbit/sage/components/templates/registrations/warranty-details/WarrantyDetailsSection.tsx';
import ServiceViewer from 'libs/elements/shared-components/src/lib/molecules/ServiceViewer';
import ModalAddNewService from '../components/organisms/ModalAddNewService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RegistrationsDetailsViewProps {}

export function RegistrationsDetailsView(props: RegistrationsDetailsViewProps) {
  const { t } = useTranslation();
  const { config, tenantInfo } = useContext(AppContext);
  const userContext = useContext(UserContext);
  const tenantLink = t('tenantLink');
  const userPermissions = getUserPermissions(tenantLink, userContext);
  const { warrantyVoid } = useSageAPI();
  const [historyLog, setHistoryLog] = useState(false);
  const [storeAddress, setStoreAddress] = useState<string>('-');
  const [showWarrantyOptions, setShowWarrantyOptions] = useState(false);
  const { consumerPersona } = useGetMySagePersonas();
  const [isVoiding, setIsVoiding] = useState(false);
  const [voidWarranty, setVoidWarranty] = useState<string>();
  const [editWarranty, setEditWarranty] = useState<string>();
  const [confirmation, setConfirmation] = useState('');
  const [newClaimModalOpen, setNewClaimModalOpen] = useState(false);
  const [warrantyDetailsForClaim, setWarrantyDetailsForClaim] =
    useState<any>(null);
  const [showRoadsideAssistancePlan, setShowRoadsideAssistancePlan] =
    useState<boolean>(false);
  const [availableOffers, setAvailableOffers] = useState<
    DTService_Offer_With_Template[] | null
  >(null);
  const [roadSideAssistancePayload, setShowRoadsideAssistancePayload] =
    useState<DTService | null>(null);
  const isAdmin = userContext?.accessToken?.admin ?? false;

  const { pathname } = useLocation();
  const holdingId = pathname.split('/')[2];
  const tenantConfigType = t('tenantConfigType');

  const data: SageConsumerDataShape | null = useGetConsumerData(holdingId);

  const { holding_proxy, allHoldings, consumer_private } = data ?? {};
  const latestWarranty: Warranty | undefined = BL_Warranty.getLatestWarranty(
    holding_proxy?.warranties
  );
  const [voided, setVoided] = useState(
    latestWarranty?.status === WarrantyStatus.VOIDED
  );

  const CFG_COBRAND_REGISTRATIONS_PRINT_SUMMARY_OPTION = t(
    'CFG_COBRAND_REGISTRATIONS_PRINT_SUMMARY_OPTION'
  );

  const isPremiumTenant =
    userContext?.accessToken?.[
      PersonaTypeSingleLetter.Warrantor + PersonaIdTypeSplitter + tenantLink
    ] === 'S' || userContext?.accessToken?.admin === true;

  let tenantType: TenantType = 'defaultTenants';
  if (tenantLink === 'WARRANTYIRELAND' || tenantLink === 'PINNACLEWARRANTIES')
    tenantType = 'carTenants';
  if (tenantLink === 'NUCOVER') tenantType = 'tableTenants';
  const warrantyInfo = BL_Warranty.getLatestWarranty(holding_proxy?.warranties);

  const getServiceOfferWithTemplate = async () => {
    const res: any = await nestApiGetAllServiceOffers(
      `tenantLink=${tenantLink}`
    );
    if (res) {
      setAvailableOffers(res.data as DTService_Offer_With_Template[]);
    }
  };

  const fetchRoadSideAssistanceOffer = (services?: DTService[] | null) => {
    if (!services?.length) {
      setShowRoadsideAssistancePayload(null);
      return;
    }
    const latestService = BL_Service.getLatestService(services);
    setShowRoadsideAssistancePayload(latestService as any);
  };

  const voidWarrantyHandler = async () => {
    setIsVoiding(true);
    try {
      if (latestWarranty && latestWarranty?.warrantyLink) {
        await warrantyVoid({
          warrantyLink: latestWarranty.warrantyLink,
        });
        toast.success(t('Warranty voided successfully!'));
        setVoidWarranty(undefined);
        setVoided(true);
      }
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsVoiding(false);
    }
  };

  useEffect(() => {
    setVoided(
      BL_Warranty.getLatestWarranty(holding_proxy?.warranties)?.status ===
        WarrantyStatus.VOIDED
    );
  }, [holding_proxy?.warranties]);

  useEffect(() => {
    if (holding_proxy?.purchase_location) {
      getRetailerLabel(holding_proxy?.purchase_location)
        .then((res) =>
          setStoreAddress(res ?? holding_proxy?.purchase_location ?? '-')
        )
        .catch((err) => console.log(err));
    } else if (holding_proxy?.purchase_location_other) {
      setStoreAddress(
        holding_proxy?.purchase_location_other === 'Shopify'
          ? t('CFG_COBRAND_SHORT_NAME')
          : holding_proxy?.purchase_location_other
      );
    }
  }, [
    holding_proxy?.purchase_location,
    holding_proxy?.purchase_location_other,
  ]);

  useEffect(() => {
    getServiceOfferWithTemplate();
  }, [tenantLink]);

  useEffect(() => {
    fetchRoadSideAssistanceOffer(holding_proxy?.services as DTService[]);
  }, [holding_proxy?.services]);

  const [favouriteStore, setFavouriteStore] = useState<string>('');

  useEffect(() => {
    if (allHoldings)
      (async () => {
        const res = await getFavouriteStore(allHoldings);
        if (res === '-' && holding_proxy?.purchase_location_other) {
          setFavouriteStore(
            holding_proxy?.purchase_location_other === 'Shopify'
              ? t('CFG_COBRAND_SHORT_NAME')
              : holding_proxy?.purchase_location_other
          );
        } else {
          setFavouriteStore(res);
        }
      })().catch((err) => console.log(err));
  }, [allHoldings]);

  // Check if the path is from Warranty Overview and the user is not an owner/warrantor -VP
  if (
    pathname.includes(SAGE_ROUTE_NAME.WARRANTY_OVERVIEW) &&
    !userPermissions?.includes(Permissions.Owner)
  )
    return <Navigate to={'/'} />;
  else if (
    !userPermissions?.includes(Permissions.RegistrationsEdit) &&
    !userPermissions?.includes(Permissions.RegistrationsView) &&
    !userPermissions?.includes(Permissions.Owner)
  )
    return <Navigate to={'/'} />;

  const canInitiateNewClaim = () => {
    return (
      userPermissions.includes(Permissions.CasesEdit) ||
      userPermissions.includes(Permissions.Owner)
    );
  };
  const validWarrantyForClaim = () => {
    if (warrantyInfo?.status !== WarrantyStatus.ACTIVE) return false;
    return true;
  };

  const fetchWarrantyDetails = async () => {
    if (holding_proxy) {
      const warrantyDetails =
        tenantConfigType === 'vehicle'
          ? {
              consumer: data?.consumer_private,
              holding: holding_proxy,
              warranties: holding_proxy.warranties,
            }
          : {
              holding: holding_proxy,
              consumer: data?.consumer_private,
            };
      setWarrantyDetailsForClaim(warrantyDetails);
      setNewClaimModalOpen(true);
    } else {
      toast.error('Registration not found');
    }
  };

  const options: {
    printing?: boolean;
    voiding?: boolean;
    editing?: boolean;
    claim?: boolean;
    roadsideAssistancePlan?: boolean;
  } = {
    printing: CFG_COBRAND_REGISTRATIONS_PRINT_SUMMARY_OPTION === 'true',
    voiding:
      (isPremiumTenant || isAdmin) &&
      config?.WARRANTIES.MANAGEMENT.CAN_VOID_WARRANTIES,
    editing:
      (isPremiumTenant || isAdmin) &&
      config?.WARRANTIES.MANAGEMENT.CAN_EDIT_WARRANTIES,
    claim: canInitiateNewClaim() && validWarrantyForClaim(),
    roadsideAssistancePlan: true,
  };

  if (!holding_proxy || !consumer_private)
    return <LoadingSpinner size={'sm'} />;

  return (
    <div className="flex flex-col gap-5">
      {voided && (
        <div className="flex flex-col gap-2 rounded-md bg-red-100 p-4">
          <div className="font-nunito flex items-center gap-1 font-bold text-red-700">
            <InformationCircleIcon className="h-4 w-4 text-red-700" />{' '}
            {t('Warranty voided at')}{' '}
            {formatUnixTime(holding_proxy?.tupdate as number, 'dd/MM/yyyy')}
          </div>
          <span className="font-nunito text-red-700">
            {t('This warranty has been voided.')}
          </span>
        </div>
      )}
      {holding_proxy && (
        <div className="flex justify-end gap-2">
          <div>
            <ButtonIcon
              kind="bgWhite"
              Icon={Cog8ToothIcon}
              onClick={() => setHistoryLog(true)}
              label={t('general.historyLog')}
              iconLeft
            />
            {historyLog && latestWarranty?.warrantyLink && (
              <ModalHistoryLog
                handleClose={() => setHistoryLog(false)}
                warrantyLink={latestWarranty?.warrantyLink}
              />
            )}
          </div>
          <div className="relative">
            {config?.WARRANTIES.MANAGEMENT.SHOW_WARRANTY_OPTIONS_BTN &&
              Object.keys(options).filter((key: any) => (options as any)[key])
                .length > 0 && (
                <ButtonIcon
                  kind="bgLightGreen"
                  Icon={Cog8ToothIcon}
                  onClick={() => setShowWarrantyOptions(true)}
                  label={t('Warranty options')}
                  iconLeft
                />
              )}
            {showWarrantyOptions && (
              <div className="absolute top-12 right-0 z-10">
                <div className="flex h-fit w-[448px] flex-col gap-4 rounded-lg bg-white p-5 shadow-lg">
                  <div className="font-nunito flex justify-between text-lg font-medium text-gray-500">
                    {t('Warranty options')}
                    <XMarkIcon
                      className="h-6 w-6 cursor-pointer"
                      onClick={() => setShowWarrantyOptions(false)}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    {options.printing && (
                      <ButtonIcon
                        kind="bgLightGreen"
                        className="w-full"
                        Icon={PrinterIcon}
                        onClick={() =>
                          window.open(`${pathname}/print`, '_blank')
                        }
                        label={t('Print warranty summary')}
                        iconLeft
                      />
                    )}
                    {options.voiding && (
                      <ButtonIcon
                        kind="bgLightGreen"
                        Icon={DocumentMinusIcon}
                        onClick={() => {
                          setVoidWarranty(holding_proxy.docid);
                          setShowWarrantyOptions(false);
                        }}
                        label={t('Void warranty')}
                        iconLeft
                        disabled={voided}
                      />
                    )}
                    {options.editing && (
                      <ButtonIcon
                        kind="bgLightGreen"
                        Icon={PencilSquareIcon}
                        onClick={() => {
                          setEditWarranty(holding_proxy.docid);
                          setShowWarrantyOptions(false);
                        }}
                        label={t('Edit warranty')}
                        iconLeft
                        disabled={voided}
                      />
                    )}
                    {options.claim && (
                      <ButtonIcon
                        kind="bgLightGreen"
                        Icon={DocumentMinusIcon}
                        onClick={() => {
                          fetchWarrantyDetails();
                          setShowWarrantyOptions(false);
                        }}
                        label={t('New Claim')}
                        iconLeft
                        disabled={voided}
                      />
                    )}
                    {config.HOLDINGS.REGISTRATION_DETAILS_VIEW
                      .ROADSIDE_ASSISTANCE && (
                      <ButtonIcon
                        kind="bgLightGreen"
                        Icon={PencilSquareIcon}
                        onClick={() => {
                          setShowRoadsideAssistancePlan(() => true);
                          setShowWarrantyOptions(false);
                        }}
                        label={t('general.addRoadsideAssistance')}
                        iconLeft
                      />
                    )}
                  </div>
                  <Button
                    kind="outline"
                    className="border-gray-200"
                    onClick={() => setShowWarrantyOptions(false)}
                  >
                    {t('Close')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {tenantType === 'carTenants' ? (
        <CarRegistrationsHoldingSummarySection
          allHoldingsList={allHoldings ?? undefined}
          holdingId={holdingId}
          holding_proxy={holding_proxy ?? undefined}
          storeAddress={storeAddress}
        />
      ) : (
        <RegistrationsHoldingSummarySection
          allHoldingsList={allHoldings ?? undefined}
          holdingId={holdingId}
          holding_proxy={holding_proxy ?? undefined}
          storeAddress={storeAddress}
        />
      )}
      <WarrantyViewer
        warranties={holding_proxy.warranties}
        tenantType={tenantType}
        tenantInfo={tenantInfo}
      />

      {roadSideAssistancePayload && (
        <ServiceViewer service={roadSideAssistancePayload} />
      )}

      {t('tenantLink') === 'TRANSPORTER' && (
        <WarrantyDetailsSection
          holding_proxy={holding_proxy}
          allHoldingsList={allHoldings}
          consumer_private={consumer_private}
          storeAddress={storeAddress}
          holdingId={holdingId}
        />
      )}

      {!consumerPersona?.personaId && (
        <RegistrationsCustDetailsSection
          allHoldingsList={allHoldings ?? undefined}
          consumer_private={consumer_private ?? undefined}
          favouriteStore={favouriteStore}
        />
      )}

      <RegistrationsHoldingDetailsSection
        holding_proxy={holding_proxy}
        allHoldingsList={allHoldings}
        consumer_private={consumer_private}
        storeAddress={storeAddress}
        holdingId={holdingId}
      />
      {config.HOLDINGS.INSTALLATION_REQUIRED && (
        <InstallationDetails
          holding_proxy={holding_proxy}
          allHoldingsList={allHoldings}
          consumer_private={consumer_private}
          storeAddress={storeAddress}
          holdingId={holdingId}
        />
      )}

      {t('tenantLink') === 'SHELTA' && (
        <RegistrationsOtherProductsSection
          allHoldingsList={
            allHoldings?.filter(
              (h) =>
                h.docid !== holdingId && h.full !== data?.holding_proxy?.full
            ) ?? null
          }
        />
      )}
      {voidWarranty && (
        <Modal
          isLoading={isVoiding}
          className="h-fit w-[350px] rounded-md bg-white"
          kind={'generic'}
          settings={{ handleClose: () => setVoidWarranty(undefined) }}
        >
          <div className="font-nunito flex flex-col gap-3 px-6 pt-6 pb-3">
            <div className="flex flex-col items-center gap-4">
              <InformationCircleIcon className="h-8 w-8 text-gray-400" />
              <div className="text-md text-center text-lg text-gray-500">
                {t(
                  'You are about to void this warranty. Are you sure you want to continue?'
                )}
              </div>
              <div className="text-medium text-gray-900">
                {t('To confirm this, type the')}
                <strong>{t('warranty ID')}</strong> {t('below')}.
              </div>
              <input
                type="text"
                className="font-nunito focus:border-primary-600 w-full rounded-lg border-[1px] border-gray-300 bg-gray-50 px-4 py-3 text-base text-gray-800 placeholder-gray-400 !shadow-none !outline-none !ring-0"
                placeholder="[WarrantyID]"
                onChange={(e) => setConfirmation(e.target.value)}
              />
            </div>
            <div className="flex gap-4">
              <Button
                kind="primary"
                type="submit"
                loading={isVoiding}
                disabled={
                  confirmation !==
                  warrantyInfo?.warrantyLink?.slice(0, 5).toUpperCase()
                }
                onClick={() => voidWarrantyHandler()}
              >
                {t('Void warranty')}
              </Button>
              <Button
                kind="outline"
                type="button"
                onClick={() => setVoidWarranty(undefined)}
              >
                {t('No, cancel')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {editWarranty && latestWarranty?.warrantyLink && (
        <ModalEditWarranty
          handleClose={() => setEditWarranty(undefined)}
          warrantyLink={latestWarranty?.warrantyLink}
        />
      )}
      {newClaimModalOpen &&
        (tenantConfigType === 'vehicle' ? (
          <ModalNewClaimVehicle
            data={warrantyDetailsForClaim}
            handleClose={() => setNewClaimModalOpen(false)}
          />
        ) : (
          <ModalNewClaim
            data={warrantyDetailsForClaim}
            handleClose={() => setNewClaimModalOpen(false)}
          />
        ))}
      {showRoadsideAssistancePlan && (
        <ModalAddNewService
          consumerLink={consumer_private.docid}
          holdingId={holdingId}
          tenantLink={tenantLink}
          handleClose={() => setShowRoadsideAssistancePlan(false)}
          shouldRefresh={fetchRoadSideAssistanceOffer}
        />
      )}
    </div>
  );
}

export default RegistrationsDetailsView;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const getRetailerLabel = async (locationId: string) => {
  if (!locationId) return undefined;
  const retailer = await FBD_Retailer_Public.get(locationId || '');
  return retailer?.name
    ? retailer?.name +
        (retailer?.addresses &&
        retailer?.addresses.length > 0 &&
        retailer?.addresses[0]?.town
          ? ' - ' + retailer?.addresses[0]?.town
          : '')
    : undefined;
};
